div.Download a {
	padding-left: 22px;
	background-repeat: no-repeat;
	background-position: 0px 0px;
	background-image: url(../images/FileSystemIcons/file.gif);
	display: block;
	min-height: 16px;
}

div.Download a.TextVersion {
	padding-left: 0;
	background-repeat: no-repeat;
	background-image: none;
}

div.Download a.pdf {
	background-image: url(../images/FileSystemIcons/Pdf.gif);
}

div.Download a.gif, div.Download a.jpg {
	background-image: url(../images/FileSystemIcons/Image.gif);
}

div.Download a.ppt {
	background-image: url(../images/FileSystemIcons/Ppt.gif);
}

div.Download a.pps {
	background-image: url(../images/FileSystemIcons/Pps.gif);
}

div.Download a.zip {
	background-image: url(../images/FileSystemIcons/Zip.gif);
}

div.Download a.xls {
	background-image: url(../images/FileSystemIcons/Xls.gif);
}

div.Download a.mp3 {
	background-image: url(../images/FileSystemIcons/Mp3.gif);
}

div.Download a.mov {
	background-image: url(../images/FileSystemIcons/Mov.gif);
}
