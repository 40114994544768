$blue: #0271b2;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;



// theme colors

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red ;
$light: $gray-100;
$dark: $gray-800;



$light-primary: lighten($primary, 58%);


$enable-rounded: false;
$font-family-sans-serif: "Helvetica", arial, sans-serif;

// fonts
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.1;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;



$input-border-radius: 0;

$table-bg: $white;
$table-border-color: $gray-300;
$table-accent-bg: $gray-200;


