/* update 2018-03-26 19:42 */
@import "./legacy/seven49cmsGlobal";

@import "variables";

/*

inactive immer grau
active grün oder gelb
- unpublished gelb
- published grün

*/

li.rtLI {
  svg {
    font-size: 1rem;
    margin-right: 4px;
  }
  .published svg {
    color: $green;
  }
  .active svg {
    color: $green;
  }
  .active.unpublished svg {
    color: $yellow;
  }
  .inactive svg {
    color: $gray-500 !important;
  }
}